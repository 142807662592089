<template>
  <div>
    <div class="rm4-card rm4-card--auto-height">
      <InputOverview :show-time="true"></InputOverview>
    </div>

    <div class="rm4-card rm4-card--p2-1">
      <div class="rm4-card__label">Please confirm</div>
      <div class="rm4-card__sub-label">
        Reservation confirmation will be sent to your email address
      </div>
      <div class="rm4-info-person-date-summary">
        <div>
          Reservation for <strong>{{ personLabel }}</strong>,
        </div>
        <div>
          <strong>{{ dateLabel }}</strong> at {{ $store.time }}h
        </div>
      </div>
      <div class="rm4-info-list">
        <div class="rm4-info-list__item">
          <div class="rm4-info-list__item__label">Email</div>
          <div class="rm4-info-list__item__value">{{ $store.email }}</div>
        </div>
        <div class="rm4-info-list__item">
          <div class="rm4-info-list__item__label">Name</div>
          <div class="rm4-info-list__item__value">{{ name }}</div>
        </div>
        <div class="rm4-info-list__item">
          <div class="rm4-info-list__item__label">Phone</div>
          <div class="rm4-info-list__item__value">{{ $store.phone }}</div>
        </div>
      </div>
      <div class="rm4-card__sub-label rm4-mt-1">
        When you reserve a table you agree to our
        <a target="_blank" href="/nl/privacy/">Terms and Conditions</a>. You
        will receive a confirmation email.
      </div>

      <div v-show="$store.loading" class="rm4-loader"></div>
    </div>

    <div class="rm4-actions" ref="actions">
      <div
        class="rm4-button rm4-button--primary"
        :class="{ 'rm4-button--disabled': $store.loading }"
        @click="save(false)"
      >
        {{ $store.isVip ? "Confirm and pay" : "Confirm and book" }}
      </div>
      <div
        class="rm4-button"
        :class="{ 'rm4-button--disabled': $store.loading }"
        @click="$modal.show('rm4-not-complete-modal')"
      >
        Back
      </div>
    </div>

    <form
      ref="adyenForm"
      v-if="adyenFormAction"
      :action="adyenFormAction"
      method="post"
    >
      <template v-if="adyenFormInputs.length">
        <input
          v-for="(adyenInput, index) in adyenFormInputs"
          :key="index"
          :name="adyenInput.name"
          :type="adyenInput.type"
          :value="adyenInput.value"
        />
      </template>
    </form>

    <modal
      name="rm4-info-modal"
      :height="'auto'"
      :adaptive="true"
      :classes="'rm4-modal-content'"
    >
      <div class="rm4-modal-container rm4-modal-container--centered">
        <div class="rm4-modal-close" @click="$modal.hide('rm4-info-modal')">
          <span class="rm4-modal-close-icon">&#10005;</span>
        </div>
        <div class="text" v-html="modalData"></div>
        <button
          class="rm4-button rm4-button--primary rm4-mt-1"
          @click="$store.currentStep = $step.FIRST"
        >
          Back
        </button>
      </div>
    </modal>

    <modal
      name="rm4-not-complete-modal"
      :height="'auto'"
      :adaptive="true"
      :classes="'rm4-modal-content'"
    >
      <div class="rm4-modal-container">
        <div
          class="rm4-modal-close"
          @click="$modal.hide('rm4-not-complete-modal')"
        >
          <span class="rm4-modal-close-icon">&#10005;</span>
        </div>
        <div>
          Your reservation is NOT complete! Are you sure you want to move away
          from this step?
        </div>
        <div class="rm4-actions rm4-actions--modal">
          <div
            class="rm4-button rm4-button--tertiary"
            @click="$store.currentStep = $step.FOURTH"
          >
            Yes
          </div>
          <div
            class="rm4-button"
            @click="$modal.hide('rm4-not-complete-modal')"
          >
            No
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import InputOverview from "./InputOverview";

export default {
  name: "Confirm",
  components: { InputOverview },
  data: function() {
    return {
      adyenFormAction: null,
      adyenFormInputs: [],
      modalData: null,
      existingStep: 0
    };
  },
  mounted: function() {
    window.onbeforeunload = function() {
      return true;
    };
  },
  methods: {
    async save() {
      this.$store.loading = true;

      try {
        const url = `${this.$apiHost}?nocache=${new Date().getTime()}`;
        const payload = {
          action: "booking",
          bookdate: moment(this.$store.date).format("YYYY-MM-DD"),
          booktime: this.$store.time,
          endtime: this.$store.endTime,
          adults: this.$store.adults,
          kids: this.$store.kids,
          bookname: this.name,
          bookemail: this.$store.email,
          bookphone: this.$store.phone,
          zipcode: this.$store.zipCode
            ? this.$store.zipCode.replace(/[^0-9a-z]/gi, "").toUpperCase()
            : null,
          birthday: this.$store.birthdate,
          domain: this.$domain,
          isSpecialBooking: Number(this.$store.isVip),
          send_sms_confirmation: 1,
          inform: this.$store.keepInformed,
          isLog: true,
          fp_fp2: this.$store.fpFp2,
          fp_guid: this.$store.fpGuid,
          existing: false,
          frame: this.getFrame(),
          pf: this.$store.isPreferredCustomer,
          pf_timeslot: this.$store.isPreferredTimeslot,
          isOutside: this.$store.isOutside,
          comment: this.$store.isOutside ? "Outside" : "",
          vue_app: 1
        };
        const { data } = await axios.post(url, payload);

        if (_.get(data, "error")) {
          this.modalData = _.get(data, "description");
          this.$modal.show("rm4-info-modal");
          this.$store.loading = false;
          this.setFpData(3, 1, this.$domain);
          return;
        }

        if (this.$store.isVip) {
          this.setFpData(4, 0, this.$domain);

          if (_.get(data, "isMolliePayment")) {
            window.location.href = _.get(data, "paymentUrl");
            return;
          }

          this.adyenFormAction = _.get(data, "paymentUrl");
          const paymentData = _.get(data, "paymentData");

          _.forIn(paymentData, function(value, key) {
            this.adyenFormInputs.push({
              type: "hidden",
              name: key,
              value
            });
          });

          setTimeout(() => this.$refs.adyenForm.submit(), 1000);
          return;
        }

        this.setFpData(4, 0, this.$domain);
        this.googleEventLog();
        this.fbPixelLog("successNormal");
        this.$store.completeDesc = _.get(data, "description");
        this.$store.currentStep = this.$step.LAST;
      } catch (error) {
        console.error(error);
      }

      this.$store.loading = false;
    },
    getFrame() {
      return {
        start: moment(
          `${moment(this.$store.date).format("YYYY-MM-DD")} ${
            this.$store.frame.time_from
          }`
        ).format("YYYY-MM-DD HH:mm:ss"),
        end: moment(
          `${moment(this.$store.date).format("YYYY-MM-DD")} ${
            this.$store.frame.time_to
          }`
        ).format("YYYY-MM-DD HH:mm:ss")
      };
    },
    googleEventLog() {
      if (typeof gtag === "function") {
        gtag( // eslint-disable-line no-undef
          "event",
          "booking",
          {
            event_category: "booking",
            event_label: "booking success"
          }
        );
      }
    },
    fbPixelLog(state) {
      let action = "";

      switch (state) {
        case "successNormal":
          action = "ReservationNormal";
          break;
        case "successVip":
          action = "ReservationCorona";
          break;
        case "failVip":
          action = "ReservationFail";
          break;
      }

      if (typeof fbq === "function") {
        fbq("trackCustom", action, { // eslint-disable-line no-undef
          custom_param1: "1"
        });
      }
    },
    goTo(refName) {
      const element = this.$refs[refName];

      window.scrollTo({
        top: _.get(element, "offsetTop"),
        behavior: "smooth"
      });
    }
  },
  computed: {
    name: function() {
      return `${this.$store.firstName} ${this.$store.lastName}`;
    },
    personLabel: function() {
      return this.$store.adults + this.$store.kids + " persons";
    },
    dateLabel: function() {
      const dayName = moment(this.$store.date).isBetween(
        moment(),
        moment().add(6, "days"),
        "date",
        "[]"
      )
        ? this.dateFormatted()
        : "";

      return `${dayName} ${moment(this.$store.date).format("DD-MM-YYYY")}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.rm4-info-person-date-summary {
  text-align: center;
  padding: 16px 0;
  border-bottom: 1px solid #e6e6e6;
}

.rm4-info-list {
  padding: 16px;
  overflow: hidden;

  .rm4-info-list__item {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }

    .rm4-info-list__item__label {
      font-size: 16px;
      color: grey;
      text-align: right;
      min-width: 70px;
      margin-right: 32px;
    }

    .rm4-info-list__item__value {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.rm4-card__sub-label {
  a {
    color: #8a927c !important;
  }
}

.rm4-mt-1 {
  margin-top: 16px;
}
</style>
